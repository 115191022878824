import { AboutPage } from '/pages/AboutPage'
import { StepsOverviewPage } from './StepsOverviewPage'
import { IS_STARTED_KEY, useLocalStorage } from '/machinery/useLocalStorage'
import { useRenderOnMount } from '@kaliber/use-render-on-mount'
import { Loader } from '/features/buildingBlocks/Loader'

export function HomePage() {
  const isMounted = useRenderOnMount()
  const [isStarted] = useLocalStorage({ key: IS_STARTED_KEY, fallbackValue: false })

  if (!isMounted)
    return <Loader />

  return (
    isStarted
      ? <StepsOverviewPage />
      : <AboutPage />
  )
}
