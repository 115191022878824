import { ContentContainer } from '/features/pageOnly/ContentContainer'
import { SpeechBorder } from '/features/buildingBlocks/SpeechBorder'
import { Icon } from '/features/buildingBlocks/Icon'
import { IconArrowLeft } from '/icons'
import { routeMap } from '/routeMap'

import styles from './Header.css'

const defaultBackLink = {
  title: 'Terug naar overzicht',
  link: routeMap.app.home({ language: 'nl' }),
}

export function Header({ backLink = defaultBackLink, children = undefined }) {
  return (
    <div className={cx(styles.component, !children && styles.noChildren)}>
      <ContentContainer>
        <div className={styles.layout}>
          {backLink && (
            <BackLink
              icon={IconArrowLeft}
              link={backLink.link}
              layoutClassName={styles.backLinkLayout}
            >
              {backLink.title}
            </BackLink>
          )}
          {children}
          {children && <SpeechBorder weight='thin' layoutClassName={styles.speechBorderLayout} />}
        </div>
      </ContentContainer>
    </div>
  )
}

function BackLink({ link, icon, children, layoutClassName }) {
  return (
    <a className={cx(styles.componentBackLink, layoutClassName)} href={link}>
      <Icon {...{ icon }} layoutClassName={styles.backLinkIconLayout} />
      <span>
        {children}
      </span>
    </a>
  )
}
