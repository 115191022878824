import { Page } from '/features/pageOnly/Page'
import { ContentContainer } from '/features/pageOnly/ContentContainer'
import { Header } from '/features/pageOnly/Header'
import { Heading62 } from '/features/buildingBlocks/Heading'
import StepsProgressOverview from '/features/pageOnly/StepsProgressOverview.universal'

export function StepsOverviewPage() {
  return (
    <Page>
      <Header backLink={null}>
        <Heading62 h={1} title="Overzicht" />
      </Header>
      <ContentContainer>
        <StepsProgressOverview />
      </ContentContainer>
    </Page>
  )
}
